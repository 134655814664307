import React from "react";
import type { Change } from "types";
import { unixTimestampToDate } from "helpers";

const changeSummary = (change: Change) => {
  const authorUsername = change.suggestion_id ? "" : change.author_username;
  switch (change.name) {
    case "deleteCategory":
      return `${authorUsername} ${change.suggestion_id ? "delete" : "deleted"} '${change.payload.categoryName}' category`;
    case "deleteItem":
      return `${authorUsername} ${change.suggestion_id ? "delete" : "deleted"} '${change.payload.itemName}' item from '${change.payload.categoryName}' category`;
    case "renameCategory":
      return `${authorUsername} ${change.suggestion_id ? "rename" : "renamed"} category '${change.payload.oldCategoryName}' to '${change.payload.newCategoryName}'`;
    case "renameItem":
      return `${authorUsername} ${change.suggestion_id ? "rename" : "renamed"} '${change.payload.oldItemName}' item to '${change.payload.newItemName}' item in '${change.payload.categoryName}' category`;
    case "updateItemCategory":
      return `${authorUsername} ${change.suggestion_id ? "move" : "moved"} '${change.payload.itemName}' item from '${change.payload.oldCategoryName}' category to '${change.payload.newCategoryName}' category`;
    case "moveItem":
      return moveItemDescription(
        authorUsername,
        change.payload.itemName,
        change.payload.oldCategoryName,
        change.payload.newCategoryName,
        change.payload.oldItemPosition,
        change.payload.newItemPosition,
        change.suggestion_id,
      );
    case "moveCategory":
      return `${authorUsername} ${change.suggestion_id ? "move" : "moved"} '${change.payload.categoryName}' from position '${change.payload.oldPosition}' to position '${change.payload.newPosition}'`;
    case "createItem":
      return createItemDescription(
        authorUsername,
        change.payload.itemName,
        change.payload.categoryName,
        change.suggestion_id,
      );
    default:
      // line below makes sure that typescript will fail
      // if we add new change.name, but forget to add a case statement for it above
      change satisfies never;
  }

  function moveItemDescription(
    author: string,
    itemName: string,
    oldCategoryName: string,
    newCategoryName: string,
    oldItemPosition: number,
    newItemPosition: number,
    suggestionId?: string,
  ) {
    const action = suggestionId ? "move" : "moved";
    if (oldCategoryName == newCategoryName) {
      return `${author} ${action} '${itemName}' from position '${oldItemPosition}' to position '${newItemPosition}'`;
    } else {
      return `${author} ${action} '${itemName}' from '${oldCategoryName}' to '${newCategoryName}' to position '${newItemPosition}'`;
    }
  }

  function createItemDescription(
    author: string,
    itemName: string,
    categoryName: string,
    suggestionId?: string,
  ) {
    const action = suggestionId ? "add" : "added";
    if (categoryName) {
      return `${author} ${action} '${itemName}' to '${categoryName}'`;
    } else {
      return `${author} ${action} '${itemName}'`;
    }
  }
};
export const ChangeComponent = ({ change }: { change: Change }) => {
  const formattedDate = change.suggestion_id
    ? ""
    : unixTimestampToDate(change.created_at).toLocaleString();

  return (
    <li className="list-group-item" id={`change-${change.id}`}>
      {formattedDate} {changeSummary(change)}
    </li>
  );
};
